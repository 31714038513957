
import { Component, Watch } from 'vue-property-decorator';
import { readMenuOptions, readSiteCode, readMenuSounds, readSites, readUserProfile, readFirstNotification } from '@/store/main/getters';
import {
  dispatchGetMenuOptions, dispatchUpdateMenuOption,
  dispatchCreateMenuOption, dispatchDeleteMenuOption,
  dispatchGetMenuSounds,
} from '@/store/main/actions';
import { IMenuOption, IMenuOptionCreate, IMenuOptionUpdate } from '@/interfaces';
import { apiUrl } from '@/env';
import VueBase from '../../components/VueBase.vue';
import { commitAddNotification } from '@/store/main/mutations';
import AudioRecorder from 'vue-audio-recorder-x';


@Component
export default class Menu extends VueBase {
  public dialogCreateUpdate: boolean = false;
  public dialogDelete: boolean = false;
  public headers = [
    { text: 'Phone button', value: 'button', sortable: false },
    { text: 'Type', value: 'type', sortable: false },
    { text: 'Option/Soundbite', value: 'option', sortable: false },
    { text: 'Actions', value: 'actions', sortable: false },
  ];
  public editedIndex: number = -1;
  public editedItem = {
    type: '',
    button: '',
    option: '',
  };
  public defaultItem = {
    type: '',
    button: '',
    option: '',
  };
  public validForm: boolean = false;
  public fileInputSound = null;

  get formTitle() {
    return this.editedIndex === -1 ? 'New Menu Option' : 'Edit Menu Option';
  }

  get menuTypes() {
    const types = [
      {value: 'showtimes', text: 'Announce Showtimes'},
      {value: 'ticket_pricing', text: 'Announce Ticket Pricing'},
      {value: 'general_information', text: 'General Information'},
      {value: 'sms_showtimes', text: 'Send SMS of today\'s showtimes'},
      {value: 'contact_phone_number', text: 'Contact phone number'},
    ];
    const profile = readUserProfile(this.$store);
    if (profile && profile?.is_superuser && profile?.is_active && !profile?.customer) {
      types.push({value: 'redirect', text: 'Redirect'});
    }
    return types;
  }

  get menuOptions() {
    return readMenuOptions(this.$store).filter((m) => m.type !== 'fone_go_welcome');
  }

  get siteCode() {
    return readSiteCode(this.$store);
  }

  get sites() {
    return readSites(this.$store);
  }

  get boNumber() {
    const site = this.sites.find((s) => s.code === this.siteCode);
    if (site) {
      return site.bo_number;
    }
    return '';
  }

  public availableButtons(item: IMenuOption) {
    const opts = readMenuOptions(this.$store);
    const busyButtons: number[] = [];
    opts.forEach((o) => busyButtons.push(parseInt(o.button, 10)));
    const availOpts: number[] = [];
    [1, 2, 3, 4, 5, 6, 7, 8, 9, 0].forEach((b) => {
      if (!busyButtons.includes(b)) {
        availOpts.push(b);
      } else if (item.button !== '' && b.toString() === item.button.toString()) {
        availOpts.push(b);
      }
    });
    return availOpts;
  }

  public async mounted() {
    await this.initialize();
  }

  public typeLabel(item: IMenuOption) {
    const types = this.menuTypes;
    for (const i in types) {
      if (types[i].value === item.type) {
        return types[i].text;
      }
    }
    return '';
  }

  public optionLabel(item: IMenuOption) {
    if (item.type === 'ticket_pricing') {
      return '';
    } else if (item.type === 'contact_phone_number') {
      if (item.option === 'BONumber') {
        return 'Box Office';
      } else if (item.option === 'theatre-information') {
        return 'Theatre Contact Information';
      } else if (item.option === 'management-office') {
        return 'Management Office';
      }
    } else if (item.type === 'redirect') {
      return '';
    } else if (item.type === 'sms_showtimes') {
      return '';
    }
    return item.option;
  }

  public editItem(item: IMenuOption) {
    this.editedIndex = this.menuOptions.indexOf(item);
    this.editedItem = Object.assign({}, item);
    this.dialogCreateUpdate = true;
  }

  public deleteItem(item: IMenuOption) {
    this.editedIndex = this.menuOptions.indexOf(item);
    this.editedItem = Object.assign({}, item);
    this.dialogDelete = true;
  }

  public async deleteItemConfirm() {
    await dispatchDeleteMenuOption(this.$store, this.editedItem.button);
    this.closeDelete();
  }

  public closeCreateUpdate() {
    this.dialogCreateUpdate = false;
    this.$validator.reset();
    this.fileInputSound = null;
    const audioRecorder = this.$refs.audioRecorder as AudioRecorder;
    if (audioRecorder) {
      audioRecorder.stopRecorder();
      audioRecorder.removeRecord(0);
    }
    this.$nextTick(() => {
      this.editedItem = JSON.parse(JSON.stringify(this.defaultItem));
      this.editedIndex = -1;
    });
  }

  public closeDelete() {
    this.dialogDelete = false;
    this.$validator.reset();
    this.fileInputSound = null;
    const audioRecorder = this.$refs.audioRecorder as AudioRecorder;
    if (audioRecorder) {
      audioRecorder.stopRecorder();
      audioRecorder.removeRecord(0);
    }
    this.$nextTick(() => {
      this.editedItem = JSON.parse(JSON.stringify(this.defaultItem));
      this.editedIndex = -1;
    });
  }

  public async save() {
    const audioRecorder = this.$refs.audioRecorder as AudioRecorder;
    if (await this.$validator.validateAll()) {
      if (
        this.editedItem.type === 'general_information' && !this.editedItem.option && !this.fileInputSound &&
        !audioRecorder.selected.blob
      ) {
        if (!readFirstNotification(this.$store)) {
          const payloadNotification = {
            content: 'Please, select one of the 3 options.',
            color: 'error',
          };
          commitAddNotification(this.$store, payloadNotification);
        }
        return;
      }

      const soundFile = this.fileInputSound ? this.fileInputSound : audioRecorder.selected.blob;
      if (['redirect', 'sms_showtimes', 'ticket_pricing'].includes(this.editedItem.type)) {
        this.editedItem.option = '';
      }
      if (this.editedIndex > -1) {
        const updatedMenuOption: IMenuOptionUpdate = {
          option: this.editedItem.option,
          type: this.editedItem.type,
          button: this.editedItem.button,
        };
        await dispatchUpdateMenuOption(
          this.$store, { button: this.editedItem.button, menuOption: updatedMenuOption, soundFile },
        );
      } else {
        const createdMenuOption: IMenuOptionCreate = {
          option: this.editedItem.option,
          type: this.editedItem.type,
          button: this.editedItem.button,
        };
        await dispatchCreateMenuOption(this.$store, {menuOption: createdMenuOption, soundFile});
      }
      this.closeCreateUpdate();
    }
  }

  public getSoundURL(item: IMenuOption, itemType: string = '') {
    if (this.siteCode) {
      const gSounds = readMenuSounds(this.$store);
      if (
        gSounds && item.type === 'general_information' && itemType === '' &&
        gSounds.general_information_custom.includes(item.option) &&
        !gSounds.general_information.includes(item.option)
      ) {
        itemType = 'general_information_custom';
      }
      return `${apiUrl}/api/v1/menu-sounds/site/${this.siteCode}/type/${itemType ? itemType : item.type}/${item.option}.mp3`;
    }
  }

  public options(item: IMenuOption) {
    const gSounds = readMenuSounds(this.$store);
    if (item.type === 'contact_phone_number') {
      if (item.option === 'AuxInfo(agi,\'BONumber\')') {
        item.option = 'BONumber';
      } else if (item.option === 'AuxInfo(agi,\'theatre-information\')') {
        item.option = 'theatre-information';
      } else if (item.option === 'AuxInfo(agi,\'management-office\')') {
        item.option = 'management-office';
      }
      return [
        {text: 'Box Office', value: 'BONumber'},
        {text: 'Theatre Contact Information', value: 'theatre-information'},
        {text: 'Management Office', value: 'management-office'},
      ];
    } else if (item.type === 'general_information' && gSounds) {
      const generalInformation = gSounds.general_information.map((s) => ({text: s, value: s}));
      const generalInformationCustom = gSounds.general_information_custom.map((s) => ({text: s, value: s}));
      return [{header: 'Menus'}, ...generalInformation, {header: 'Custom Sounds'}, ...generalInformationCustom];
    } else if (item.type !== '' && gSounds && item.type in gSounds) {
      return gSounds[item.type];
    }
    return [];
  }

  public typeUpdated() {
    this.editedItem.option = '';
  }

  @Watch('dialogCreateUpdate')
  private watchDialog(val: boolean) {
    if (!val) {
      this.closeCreateUpdate();
    }
  }

  @Watch('dialogDelete')
  private watchDialogDelete(val: boolean) {
    if (!val) {
      this.closeDelete();
    }
  }

  private async initialize() {
    if (this.siteCode) {
      await dispatchGetMenuOptions(this.$store);
      await dispatchGetMenuSounds(this.$store);
    }
  }

  @Watch('siteCode')
  private async watchSiteCode(val: string) {
    await this.initialize();
  }
}
